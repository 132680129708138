<template>
  <b-card class="mb-2 library-card answer-links course-card p-2" align="left">
    <b-row class="mx-0">
      <div class="d-flex align-items-center">
        <span class="padding-passage fz-24 fw-400 eta-title">{{lesson.title }}</span>
      </div>
    </b-row>
    <div class="eta-subtitle d-flex justify-content-between">
      <span>{{ lesson.subtitle }}</span>
                <img @click="goDocUrl" class="cursor-pointer library-document-action-icon"
               :src="'/assets/images/right-arrow.png'" />
    </div>
    <markdown-to-html class="txt-black" :source="lesson.description" :docId="lesson.title"></markdown-to-html>

    <b-row class="d-flex library-document-bottom-elements mx-0">
      <b-input-group class="qa-input-group">
        <b-form-input class="knowledge-qa-input-search-bar eta-input" type="search"
                      v-model="textFilter"
                      :placeholder="$t('myApps.qaPairs.filters.search')"
                      @keyup.enter="searchAsk">
        </b-form-input>
        <b-input-group-append class="eta-search-icon" @click="searchAsk">
          <img class="cursor-pointer padding-icon" :src="'/assets/images/icon_knowledge_qa_search.svg'"/>
        </b-input-group-append>
      </b-input-group>
    </b-row>
  </b-card>
</template>

<script>
import MarkdownToHtml from "@/modules/common/components/MarkdownToHtml";
export default {
name: "CourseList",
  components: {MarkdownToHtml},
  props: {
    lesson: Object
  },
  data() {
    return {
      textFilter: ''
    }
  },
  methods: {
    goDocUrl() {
      this.$router.push(`/${this.$store.getters.getInstance}/document-by-tags/${this.lesson.tags}`)
    },
    searchAsk() {
      this.textFilter = this.textFilter.trim()
      this.$router.push(`/${this.$store.getters.getInstance}/my-apps/knowledge/ask_results?question=${this.textFilter}&tags_ask=${this.lesson.tags}`)
    }
  },
}
</script>

<style lang="scss" scoped>

.padding-icon {
  padding:10px;
}
.course-card {
  background-color: #f2f2f2;
  box-shadow: 0px 0px 16px #c7c7c7 !important;
  border: unset;
  margin-bottom: 12px;
}

.eta-subtitle {
  background-color: #9b9b9b !important;
  border-left: 10px solid #ce7a28 !important;
  padding: 12px 0px 12px 6px;
  margin-top: 8px;
  margin-bottom: 8px;

  span {
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin: auto 0px auto 6px;
  }

  img {
    margin-right: 8px;
  }
}

.eta-title {
  color: #494949;
}

.eta-input {
  border-radius: 0;
  border: 1px solid black;
  border-right: unset;
  background-color: transparent;
  max-width: 50%;
  margin-bottom: 0px !important;
}

input.eta-input:focus {
  outline: unset;
  box-shadow: unset !important;
}

.eta-search-icon {
  border-radius: 0;
  border: 1px solid black;
  border-left: unset;
  background-color: transparent;
  padding-right: 12px;
  filter: brightness(0%)
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}
</style>