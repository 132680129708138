import Repository from '@/modules/common/utils/Repository.js'
import store from "@/store";

export default {
  getCourses() {
    return Repository.dbService.get('ask/get_courses', {
      params: {
        'bot_name': store.getters.getInstance
      }
    })
  }
}
