<template>
  <div class="container-base pl-0">
    <div class="eta-container">
      <div class="table-list-title fw-400 fz-22 my-2 text-left">
        <b-row class="file-name-back mx-0">
          <div class="library-document-title fw-300 fz-32 file-title-trim w-100 pl-15 eta-title">
            {{ courseSelected.title }}
          </div>
        </b-row>
      </div>

      <b-row class="justify-content-between margin-title-row ta-left mx-0 course-card">
        <span class="d-block fz-20 fw-600 txt-black my-auto">SEARCH BY COURSE</span>
        <div class="d-block fz-10 fw-100 my-auto" v-for="(course, idx) in searchByCourse" :key="idx">
          <input type="radio" v-model="byCourse" name="byCourse" :value="course.label" :key="course.label"> {{course.label}}
        </div>
        <div class="w-50">
          <b-input-group class="qa-input-group">
            <b-form-input class="li-utility-set-detail-description fw-100 fz-17 eta-input" type="text"
                          :placeholder="$t('eta.searchInput')"
                          v-model="textFilter"
                          @keyup.enter="searchAsk">
            </b-form-input>
            <b-input-group-append class="eta-search-icon" @click="searchAsk">
              <img class="cursor-pointer padding-icon" :src="'/assets/images/icon_knowledge_qa_search.svg'"
                  />
            </b-input-group-append>
          </b-input-group>
        </div>
      </b-row>

      <br>
      <div v-for="lesson in courseSelected.lessons" :key="lesson.title">
        <course-list class="mb-4" :lesson="lesson"/>
      </div>

    </div>

    <div class="eta-footer py-3">
      <span class="fw-600">Copyright © 2021 <span @click="copyright" class="cursor-pointer eta-link-highlight">electrical training ALLIANCE</span></span>
    </div>

  </div>
</template>

<script>
import TrainingService from "@/modules/eta/services/TrainingService";
import CourseList from "@/modules/eta/components/CourseCard";

export default {
name: "Lesson",
  components: {CourseList},
  data() {
    return {
      courseSelected: {},
      textFilter: '',
      searchByCourse: [],
      byCourse: 'Course Content'
    }
  },
  methods: {
    searchAsk() {
      this.textFilter = this.textFilter.trim()
      let tagTitle = (this.searchByCourse.length) ? this.getTagsByCourse() : this.courseSelected.lessons[0].tags[0]
      this.$router.push(`/${this.$store.getters.getInstance}/my-apps/knowledge/ask_results?question=${this.textFilter}&tags_ask=${tagTitle}`)
    },
    copyright() {
      window.location = `https://www.electricaltrainingalliance.org/`
    },
    getTagsByCourse(){
      return this.searchByCourse.filter(course => course.label === this.byCourse)[0].tags.toString()
    }
  },
  created() {
    let courseName = this.$router.currentRoute.params.courseName
    TrainingService.getCourses().then(response => {
      this.courseSelected = response.data['orientation'].courses.find(course => course['course_name'] === courseName)
      this.searchByCourse = this.courseSelected['search_by_course']
    })
  },
  mounted() {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>

.padding-icon {
  padding: 10px;
}

.eta-title {
  border-bottom: 1px solid black;
  padding-bottom: 12px;
  margin-bottom: 20px;
}

.eta-container {
  max-width: 95%;
  padding-left: 35px;
}

.course-card {
  background-color: #f2f2f2;
  box-shadow: 0px 0px 16px #c7c7c7 !important;
  border: unset;
  padding: 12px 15px;
}

.eta-input {
  border-radius: 0;
  border: 1px solid black;
  border-right: unset;
  background-color: transparent;
}

input.eta-input:focus {
  outline: unset;
  box-shadow: unset !important;
}

.eta-search-icon {
  border-radius: 0;
  border: 1px solid black;
  border-left: unset;
  background-color: transparent;
  padding-right: 12px;
  filter: brightness(0%)
}

.eta-footer {
  background-color: #3a3a3a;
  margin-top: 75px;

  span {
    color: #CE7B01;
  }
}

.fw-400 {
  font-weight: 400;
}

.fw-600 {
  font-weight: 600;
}

.fw-100 {
  font-weight: 100;
}

.fw-300 {
  font-weight: 300;
}

span.eta-link-highlight:hover {
  color: white;
  text-decoration: underline;
}
</style>